import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import AdminData from '../../utility/AdminData';

interface Admin {
    uname: string;
    uid: string;
    created_at: string;
    role: string;
    f_name: string | null;
    m_name: string | null;
    l_name: string | null;
    profile_image: string;
}

export default function SystemConfiguration() {
    const [editState, setEditState] = useState<boolean>(false);
    const [configData, setConfigData] = useState<any>([]);
    const [adminData, setAdminData] = useState<Admin | null>(null);
    const token = localStorage.getItem('token');

    function AdminData(): any {
        const token = localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_ADMIN_API}/etc/my-profile`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            const data = res.data;
            setAdminData(data);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        AdminData();
    }, []);
 
    function getSystemConfiguration() {
        axios.get(`${process.env.REACT_APP_ADMIN_API}/system/config`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            if(res.data.length) {
                const configData = {
                    WITHDRAW_TOGGLE: res.data.find((config: any) => config.setting === 'WITHDRAW_TOGGLE')?.value,
                }
                setConfigData(configData);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getSystemConfiguration();
    }, []);

    function updateConfiguration() {
        setEditState(!editState);

        if(editState && configData) {
            axios.post(`${process.env.REACT_APP_ADMIN_API}/system/config`,
                {
                    ...configData,
                    adminUsername: adminData?.uname
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            ).then((res) => {
                    Swal.fire({
                        title: 'Update System Configurations สำเร็จ',
                        icon: 'success'
                    });

                    // handleSearch();
                    getSystemConfiguration();
            })
            .catch((err) => {
                console.log(err);
                Swal.fire({
                    title: err.response.data.message,
                    icon: 'error'
                });
                getSystemConfiguration();
            });
        }
    }

    return (
        <main className={`w-full h-[1000px] border`}>
            <div className="w-full h-auto flex justify-center items-center mt-12 md:mt-0">
                <h1 className="text-3xl">System Configuration | </h1>
                <img className="w-[150px] h-auto" src="/imgs/logo.jpg" alt="brand-logo" />
            </div>

            <div className="flex flex-row w-10/12 md:w-8/12 mx-auto mt-4 items-center">
                <p className="sm:text-lg text-s w-1/2">Withdrawal system toggle</p>
                <button 
                    className={`h-10 w-1/4 ${parseInt(configData.WITHDRAW_TOGGLE) ? 'bg-green-500' : 'bg-grey-200'} mx-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, WITHDRAW_TOGGLE: '1' })}
                    disabled={!editState}
                >
                    ON
                </button>
                <button 
                    className={`h-10 w-1/4 ${!parseInt(configData.WITHDRAW_TOGGLE) ? 'bg-red-500' : 'bg-grey-200'} ml-2 border-2 rounded-lg`}
                    onClick={() => setConfigData({ ...configData, WITHDRAW_TOGGLE: '0' })}
                    disabled={!editState}
                >
                    OFF
                </button>
            </div>

            <div className="flex w-10/12 md:w-8/12 mx-auto mt-8">
                <button className="h-12 w-full bg-black text-white rounded-xl" onClick={updateConfiguration}>
                    { editState ? 'Save Configurations' : 'Edit Configurations' }
                </button>
            </div>
        </main>
    );
}
